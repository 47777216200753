<template>
  <div class="app-card-image-title">
    <img :src="imgSrc" :alt="alt" class="image-title-image" />
    <h2 v-if="title" class="image-title-title">
      <strong>{{ title }}</strong>
    </h2>
    <!-- Default slot -->
    <div class="image-title-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'AppCardImageTitle',
  emits: [],
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const imgSrc = computed(() => {
      const { src } = props
      if (src && src.startsWith('http')) {
        return src
      }
      try {
        return require(`@/assets/images/${src}`)
      } catch {
        return ''
      }
    })

    return {
      imgSrc,
    }
  },
}
</script>

<style lang="postcss" scoped>
.app-card-image-title {
  .image-title-image {
    @apply ml-auto mr-auto;
    @apply mb-6;
  }

  .image-title-title {
    @apply type-h1 mb-4 text-center;

    @screen sm {
      @apply mb-2;
    }
  }

  .image-title-content {
    @apply text-center;
  }
}
</style>
