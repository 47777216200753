<template>
  <div class="register-creating">
    <Transition name="fade" mode="out-in">
      <!-- Creating account -->
      <div v-if="createAccount.loading">
        <AppSpinnerBig class="loading-creating" :loading="true" :inline="true"></AppSpinnerBig>
        <AppCardImageTitle
          key="creating"
          class="creating-account"
          title="We are almost ready!"
          src="illustrations/rocket.svg"
          alt="We are almost ready!"
        >
          <p class="text-gray type-subtitle">
            We’re creating your account...<br />
            This should take no longer than <b>30 seconds.</b>
          </p>
        </AppCardImageTitle>
      </div>

      <!-- Account already registered error -->
      <AppModal
        v-else-if="accountAlreadyRegistered"
        :value="true"
        :dismissible="true"
        :content-centered="true"
      >
        <AppCardImageTitle
          title="You’re already registered"
          src="illustrations/close-outline.svg"
          alt="Something happened"
          class="m-auto"
        >
          <p class="text-gray type-subtitle">
            Looks like your company already has an account. Try to login instead.
          </p>
        </AppCardImageTitle>

        <template #footer>
          <AppModalFooter>
            <AppButton @click="login"> Login </AppButton>
            <AppButton class="contact-us" theme="secondary" @click="contactUs">
              Contact us
            </AppButton>
          </AppModalFooter>
        </template>
      </AppModal>

      <!-- Account creation error -->
      <AppModal
        v-else-if="createAccount.error"
        :value="true"
        :dismissible="true"
        :content-centered="true"
      >
        <AppCardImageTitle
          title="We’ve encountered an issue"
          src="illustrations/close-outline.svg"
          alt="Something happened"
          class="m-auto"
        >
          <p>Sorry, there’s an unexpected error. Please try again later.</p>
        </AppCardImageTitle>

        <template #footer>
          <AppModalFooter>
            <AppButton @click="onErrorBtnClicked('restart')"> Try again </AppButton>
            <AppButton class="contact-us" theme="secondary" @click="contactUs">
              Contact us
            </AppButton>
          </AppModalFooter>
        </template>
      </AppModal>

      <!-- Account successfully created -->
      <AppModal
        v-else-if="accountCreatedSuccess"
        :value="true"
        :dismissible="true"
        :content-centered="true"
      >
        <AppCardImageTitle
          v-if="accountCreatedSuccess"
          key="success"
          class="created-account"
          title="Account created "
          alt="Account created"
          src="illustrations/created.svg"
        >
          <p v-if="isLiteRegistration">
            <b>Your account is ready to use</b>
            <br />
            <br />
            Please use the login details you provided at registration to access Xe from the platform
          </p>
          <p v-else class="text-gray type-subtitle">
            You have limited access to your account while it’s being reviewed. Someone from our team
            will call you shortly.
          </p>
        </AppCardImageTitle>

        <template #footer>
          <AppModalFooter>
            <AppButton v-if="isLiteRegistration" :loading="proceeding" @click="logout">
              Sign out
            </AppButton>
            <AppButton v-else :loading="proceeding" @click="proceed">Okay</AppButton>
          </AppModalFooter>
        </template>
      </AppModal>
    </Transition>
    <!-- Confirm that the user wants to leave this back and go 'back' -->
    <AppDialog v-model="showLeaveDialog" :dismissible="true">
      <template #header>
        <AppDialogHeader>
          <h2>Are you sure you want to leave this page?</h2>
        </AppDialogHeader>
      </template>
      <template #footer>
        <AppDialogFooter>
          <AppButton theme="text" @click="acceptLeavePage">Yes, I'm sure</AppButton>
          <AppButton @click="showLeaveDialog = false">Cancel</AppButton>
        </AppDialogFooter>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, computed } from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'

import { useRouter } from '@/composables/useRouter'

import AppModal from '@/components/AppModal/AppModal'
import AppCardImageTitle from '@/components/AppCardImageTitle/AppCardImageTitle'
import AppModalFooter from '@/components/AppModalFooter/AppModalFooter'
import AppButton from '@/components/AppButton/AppButton'
import AppSpinnerBig from '@/components/AppSpinnerBig/AppSpinnerBig'

import AppDialog from '@/components/AppDialog/AppDialog'
import AppDialogHeader from '@/components/AppDialogHeader/AppDialogHeader'
import AppDialogFooter from '@/components/AppDialogFooter/AppDialogFooter'

import { useAnalyticsStore } from '@/stores/analytics'
import { useDashboardStore } from '@/stores/dashboard'
import { useAuthStore } from '../../stores/auth'
import { useCompanyDetailsStore } from '@/stores/companyDetails'
import { useCorporateRegistrationStore } from '@/stores/corporateRegistration'
import { useAppStore } from '@/stores/app'

export default {
  name: 'RegisterBusinessCreating',
  components: {
    AppModal,
    AppCardImageTitle,
    AppModalFooter,
    AppButton,
    AppSpinnerBig,
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
  },
  beforeRouteLeave(to, from, next) {
    if (this.removeLeaveDialog) {
      next()
    } else {
      next(false)
      this.showLeaveDialog = true
    }
  },
  setup() {
    const analyticsStore = useAnalyticsStore()
    const dashboardStore = useDashboardStore()
    const authStore = useAuthStore()
    const companyDetailsStore = useCompanyDetailsStore()
    const corporateRegistrationStore = useCorporateRegistrationStore()
    const appStore = useAppStore()

    const router = useRouter()
    const showLeaveDialog = ref(false)
    const removeLeaveDialog = ref(false)
    const isLiteRegistration = computed(() => authStore.isLiteRegistration === true)

    corporateRegistrationStore.activeStepIdx = 3
    corporateRegistrationStore.setActiveStepPageTitle('Creating')

    const accountCreatedSuccess = ref(false)
    const accountAlreadyRegistered = ref(false)

    const createAccount = reactive(
      usePromiseLazy(() => corporateRegistrationStore.submitRegistration())
    )

    onMounted(async () => {
      accountCreatedSuccess.value = false
      const companyType = companyDetailsStore.companyType // Company type id

      const selectedCountry = companyDetailsStore.companyCountry

      try {
        await createAccount.exec()
        if (!createAccount.error) {
          if (createAccount.result && createAccount.result.success) {
            const clientNumber = corporateRegistrationStore.clientNumber

            await corporateRegistrationStore.updateMarketingPreferences()

            // Send GTM variable - Registration_Email
            analyticsStore.gtmTrack({
              event: 'Registration_ClientNumber',
              variables: {
                Registration_ClientNumber: clientNumber,
              },
            })

            // Send GTM event - Event_Registration_Complete
            analyticsStore.gtmTrack({
              event: 'Event_Registration_Complete',
              variables: {},
            })

            // Send data to Amplitude/Segment - Event triggered on Thank You Page load
            analyticsStore.identify({
              userId: authStore.profileId,
              traits: {
                referenceSource: 'XEMT Business',
                email: authStore.lastLogin,
                clientNumber,
              },
            })

            analyticsStore.track({
              event: 'Profile Created',
              traits: {
                referenceSource: 'XEMT Business',
                release: appStore.version,
                clientNumber,
              },
            })

            // Allowed company types
            // US: Corporation, Limited Liability Company, Sole Proprietorship
            // CA: Private, Sole Proprietor
            // UK: Limited, Sole
            // APAC ('AU', 'NZ', 'NF', 'CK'): Limited or Sole
            let regionCompanyTypesMapping = new Map(
              dashboardStore.pendingActionsConfig.allowedForPendingActionsById
            )

            // Dashboard redirect
            if (selectedCountry === 'MY') {
              createAccount.loading = true
              window.removeEventListener('beforeunload', window.handleWindowClose)
              window.location = 'https://help.xe.com/hc/en-gb/articles/4408594616721' // error placeholder page for my customers
            } else if (regionCompanyTypesMapping.get(selectedCountry)?.includes(companyType)) {
              // TODO: Check best condition to handle the company type (use company type id or name?)
              window.removeEventListener('beforeunload', window.handleWindowClose)
              dashboardStore.isFirstLoad = true
              window.location = corporateRegistrationStore.getRedirectUrl
            } else {
              corporateRegistrationStore.resetForm()
              accountCreatedSuccess.value = true
            }
          } else {
            // TODO error !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            createAccount.error = true
          }
        }
      } catch (ex) {
        if (ex.errorCode === 'alreadyRegistered') {
          accountAlreadyRegistered.value = true
        } else {
          createAccount.error = true
        }
        appStore.logException({
          text: 'Exception during registration creation',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
    })

    const login = async () => {
      router.push({ name: 'home' })
    }

    const acceptLeavePage = () => authStore.sendToContinueUs()

    const proceeding = ref(false)
    const proceed = async () => {
      window.removeEventListener('beforeunload', window.handleWindowClose)
      proceeding.value = true
      corporateRegistrationStore.proceedRegistration()
    }

    const logout = async () => {
      proceeding.value = true
      await authStore.logout()
    }

    const onErrorBtnClicked = (button) => {
      if (button === 'exit') {
        router.push({ name: 'home' })
      } else if (button === 'restart') {
        router.push({ name: 'RegisterBusinessInformation' })
      }
    }

    const contactUs = () => {
      corporateRegistrationStore.contactUs()
    }

    return {
      createAccount,
      accountCreatedSuccess,
      accountAlreadyRegistered,
      proceeding,
      showLeaveDialog,
      removeLeaveDialog,
      isLiteRegistration,
      login,
      proceed,
      contactUs,
      onErrorBtnClicked,
      acceptLeavePage,
      logout,
    }
  },
}
</script>

<style lang="postcss" scoped>
.register-creating {
  @apply w-full flex items-center justify-center;
  height: calc(100vh - 150px);

  & > * {
    @apply flex items-center flex-col;
  }

  /deep/ .card-content {
    @apply m-auto p-6;
    @apply pb-0 !important;

    @screen sm {
      /* Resets */
      @apply p-12;
    }
  }

  /deep/ .card--has-footer {
    @apply pb-0;
  }
}
.creating-account {
  /deep/ .image-title-image {
    @apply mb-16;
  }

  /deep/ .type-subtitle {
    @apply mt-8;
  }
}
.creating-account,
.created-account {
  /deep/ .image-title-title {
    @apply text-center;
    @apply px-6;
  }
}

.card-footer {
  @apply mt-8;
  @apply flex-col;
  @apply shadow-none;
}

.loading-creating {
  position: relative;
  top: -50px;
  transform: scale(2);
}

.contact-us {
  @apply mt-4;
  @apply text-primary-text;
  @apply bg-blue-light;
}

.sub-card {
  @apply mt-6;
  @apply w-full mb-6 border border-gray-light rounded-2xl shadow-ria-1;
}
</style>
